<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">申诉列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="
            operationControl
            flexdc
            operationControlCurr
            operationControlWidth
          "
          style="align-items: flex-start"
        >
          <div class="searchbox" style="width: 100%">
            <div>
              <div title="身份证号" class="searchboxItem searchboxItems">
                <span class="itemLabel">身份证号:</span>
                <el-input
                  v-model="idcard"
                  type="text"
                  size="small"
                  placeholder="请输入身份证号"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="班级名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="projectName"
                  type="text"
                  size="small"
                  placeholder="请输入班级名称"
                  clearable
                  style="flex: 1"
                />
              </div>
              <div title="申诉人姓名" class="searchboxItem searchboxItems">
                <span class="itemLabel">申诉人姓名:</span>
                <el-input
                  v-model="userName"
                  type="text"
                  size="small"
                  placeholder="请输入申诉人姓名 "
                  clearable
                />
              </div>
              <div title="申诉人电话" class="searchboxItem searchboxItems">
                <span class="itemLabel">申诉人电话:</span>
                <el-input
                  v-model="mobile"
                  type="text"
                  size="small"
                  placeholder="请输入申诉人电话"
                  clearable
                  style="flex: 1"
                />
              </div>
            </div>
          </div>
          <div
            class="searchbox searchboxTwo"
            style="width: 100%; margin-top: 5px"
          >
            <div>
              <div title="机构名称" class="searchboxItem searchboxItems">
                <span class="itemLabel">机构名称:</span>
                <el-input
                  v-model="compName"
                  type="text"
                  size="small"
                  placeholder="请输入机构名称 "
                  clearable
                />
              </div>
              <div title="处理状态" class="searchboxItem searchboxItems">
                <span class="itemLabel">处理状态:</span>
                <el-select
                  size="small"
                  v-model="appealStatus"
                  clearable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in appealStatusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
              <div title="申诉时间" class="searchboxItem searchboxItems">
                <span class="itemLabel">申诉时间:</span>
                <el-date-picker
                  clearable
                  size="small"
                  v-model="appealTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div
            class="searchbox searchboxTwo"
            style="width: 100%; margin-top: 5px"
          >
            <div>
              <div title="处理时间" class="searchboxItem searchboxItems">
                <span class="itemLabel">处理时间:</span>
                <el-date-picker
                  clearable
                  size="small"
                  v-model="processingTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="起始时间"
                  end-placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </div>

              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </div>
            </div>
          </div>
        </div>

        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <!-- @sort-change="changeSort"
              :default-sort="{prop: 'appealDisposeTime', order: 'ascending'}"-->
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="申诉时间"
                align="left"
                show-overflow-tooltip
                width="180px"
              >
                <template slot-scope="scope">
                  {{ scope.row.appealTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="250px"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="150px"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                width="120px"
              />
              <el-table-column
                label="申诉人姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
              />
              <el-table-column
                label="申诉人电话"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                width="120px"
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                width="200px"
              />
              <el-table-column
                label="处理状态"
                align="center"
                show-overflow-tooltip
                width="120px"
              >
                <template slot-scope="scope">{{
                  $setDictionary("APPROVESTATE", scope.row.appealStatus)
                }}</template>
              </el-table-column>
              <el-table-column
                label="处理时间"
                align="left"
                show-overflow-tooltip
                width="180px"
              >
                <!-- :sort-orders="['ascending', 'descending']"
                 sortable="custom"
                prop='appealDisposeTime'-->
                <template slot-scope="scope">
                  {{ scope.row.appealDisposeTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" fixed="right">
                <div slot-scope="scope">
                  <el-button
                    v-if="scope.row.appealStatus == '10'"
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="
                      ProcessingDetails(
                        scope.row.appealId,
                        scope.row.appealStatus
                      )
                    "
                    >处理</el-button
                  >
                  <el-button
                    v-else
                    type="text"
                    size="mini"
                    style="padding: 0 5px"
                    @click="
                      ProcessingDetails(
                        scope.row.appealId,
                        scope.row.appealStatus
                      )
                    "
                    >详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      width="1000px"
      title="申诉信息"
      @close="closeCode"
      :visible.sync="DetailsVisible"
      top="3%"
    >
      <section style="height: 670px">
        <div class="ovy-a">
          <el-form ref="ruleForm" class="courseBox" label-width="10rem">
            <el-form-item label="申诉时间：" class="form-item">
              <p>{{ appealInfor.appealTime | moment }}</p>
            </el-form-item>
            <el-form-item label="班级名称：" class="form-item">
              <p>{{ appealInfor.projectName }}</p>
            </el-form-item>
            <el-form-item label="机构名称：" class="form-item">
              <p>{{ appealInfor.compName }}</p>
            </el-form-item>
            <el-form-item label="行政地区：" class="form-item">
              <p>{{ appealInfor.areaName }}</p>
            </el-form-item>

            <el-form-item label="申诉人姓名：" class="form-item">
              <p>{{ appealInfor.userName }}</p>
            </el-form-item>
            <el-form-item label="申诉人电话：" class="form-item">
              <p>{{ appealInfor.mobile }}</p>
            </el-form-item>
            <el-form-item label="身份证号：" class="form-item">
              <p>{{ appealInfor.idcard }}</p>
            </el-form-item>
            <el-form-item label="处理状态：" class="form-item">
              <p>
                {{ $setDictionary("APPROVESTATE", appealInfor.appealStatus) }}
              </p>
            </el-form-item>
            <el-form-item
              label="认证图片："
              style="width: 50%"
              prop="thumbnail"
            >
              <div class="img-el-upload" style="width: 256px">
                <el-image
                  style="width: 50%; heigth: 50%"
                  :src="appealInfor.userFace"
                  fit="contain"
                ></el-image>
              </div>
            </el-form-item>
            <el-form-item
              label="申诉照片："
              style="width: 50%"
              prop="thumbnail"
              v-if="appealInfor.appealFaceUrl"
            >
              <div class="img-el-upload" style="width: 256px">
                <el-image
                  style="width: 50%; heigth: 50%"
                  :src="appealInfor.appealFaceUrl"
                  fit="contain"
                ></el-image>
              </div>
            </el-form-item>
            <el-form-item label="点名时间：" class="form-item">
              <p>
                {{ appealInfor.callTime }} (<span style="color: red">
                  间隔: {{ getTime(appealInfor.intervalSecond) }}</span
                >)
              </p>
            </el-form-item>
            <el-form-item
              v-if="
                appealInfor.approveFace && appealInfor.approveFace.length > 0
              "
              :rules="appealInfor.appealStatus != '20' ? rules : ''"
              label="点名照片："
              style="white-space: nowrap"
              prop="thumbnail"
            >
              <div style="overflow: auto; display: flex; flex-wrap: wrap">
                <div
                  class="img-el-upload"
                  style="width: 256px; margin-left: 20px; margin-bottom: 20px"
                  v-for="(item, index) in appealInfor.approveFace"
                  :key="index"
                >
                  <el-image
                    :class="{ theImgClick: theImgindex == index }"
                    @click="imgClick(item.faceId, index)"
                    :src="item.userFace"
                    fit="contain"
                    style="width: 50%; heigth: 50%"
                  ></el-image>
                </div>
              </div>
            </el-form-item>
            <el-form-item
              v-if="appealInfor.appealStatus == '10' ? true : false"
              label="处理备注："
              style="width: 90%"
              class="form-item"
            >
              <el-input
                type="textarea"
                maxlength="100"
                v-model="appealMessage"
                placeholder="请输入驳回理由"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="appealInfor.appealStatus == '10' ? true : false"
              style="width: 90%"
            >
              <div style="text-align: right">
                <el-button
                  class="bgc-bv"
                  @click="doSave(theAppealId, appealInfor.appealFaceUrl)"
                  :disabled="!appealInfor.appealFaceUrl && !faceId"
                  :class="{ noPassBtn: !appealInfor.appealFaceUrl && !faceId }"
                  >通过</el-button
                >
                <el-button
                  class="bgc-bv"
                  @click="doGive(theAppealId, appealInfor.appealStatus)"
                  >驳回</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              label="审核备注："
              v-if="
                appealInfor.appealMessage != '' &&
                appealInfor.appealStatus == '30'
              "
              style="width: 90%"
              class="form-item"
            >
              <p>{{ appealInfor.appealMessage }}</p>
            </el-form-item>
            <el-form-item
              v-if="appealInfor.appealStatus == '30' ? true : false"
              style="width: 90%"
            >
              <div style="text-align: right">
                <el-button
                  class="bgc-bv"
                  @click="doSave(theAppealId, appealInfor.appealFaceUrl)"
                  :disabled="!appealInfor.appealFaceUrl && !faceId"
                  :class="{ noPassBtn: !appealInfor.appealFaceUrl && !faceId }"
                  >通过</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </section>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "AppeaList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //查询条件
      idcard: "", //身份证号
      projectName: "", //班级名称
      userName: "", //申诉人姓名
      mobile: "", //申诉人电话
      compName: "", //机构名称
      appealStatus: "", //处理状态
      appealStatusList: [],
      appealTime: "", // 申诉时间
      processingTime: "", //处理时间
      DetailsVisible: false,
      theAppeal: false,
      appealInfor: {},
      theAppealId: "",
      appealMessage: "", //驳回理由
      ruleForm: {},
      rules: [{ required: true, trigger: "change", message: "请选择通过图片" }],
      theImgindex: -1,
      faceId: "",

      // auditTimeStort:''  排序需要传递给后端的值
    };
  },
  created() {
    this.getappealStatus();
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    //处理时间排序
    //    changeSort (val) {
    //   this.auditTimeStort=val.order;
    //     this.getData();
    //  },

    closeCode() {
      this.theImgindex = -1;
      this.faceId = "";
    },
    //点击详情或者 处理
    ProcessingDetails(appealId, appealStatus) {
      this.appealMessage = "";
      this.theAppealId = appealId;
      this.DetailsVisible = true;
      if (appealStatus == 10) {
        this.theAppeal = true;
      }
      this.$post("/biz/course/appeal/queryCourseAppealInfo", {
        appealId: appealId,
      }).then((res) => {
        this.appealInfor = res.data;
      });
    },
    //通过
    doSave(appealId, appealFaceUrl) {
      if (!appealFaceUrl && !this.faceId) {
        this.$message({
          showClose: true,
          message: "未检测到您有申诉照片或您未选择点名照片！",
          type: "warning",
        });
      } else {
        this.$post("/biz/course/appeal/doAppeal", {
          appealId: appealId,
          appealStatus: "20",
          faceId: this.faceId,
        }).then((res) => {
          if (res.status == 0) {
            this.DetailsVisible = false;
            this.$message({
              showClose: true,
              message: "通过成功！",
              type: "success",
            });
            this.getData(-1);
          }
        });
      }
      this.theImgindex = -1;
    },
    //驳回
    doGive(appealId, appealStatus) {
      const params = {
        appealId: appealId,
        appealStatus: "30",
      };
      if (this.appealMessage) {
        params.appealMessage = this.appealMessage;
      }
      this.$post("/biz/course/appeal/doAppeal", params).then((res) => {
        if (res.status == 0) {
          this.DetailsVisible = false;
          this.$message({
            showClose: true,
            message: "驳回成功",
            type: "success",
          });
          this.getData(-1);
        }
      });
      this.theImgindex = -1;
    },
    //选择 通过图片
    imgClick(id, index) {
      this.faceId = id;
      if (
        this.appealInfor.appealStatus == "10" ||
        this.appealInfor.appealStatus == "30"
      ) {
        this.theImgindex = index;
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 80 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      // if (this.auditTimeStort) {
      //   params.auditTimeStort = this.auditTimeStort
      // }
      if (this.idcard) {
        params.idcard = this.idcard;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.appealStatus) {
        params.appealStatus = this.appealStatus;
      }
      if (this.appealTime) {
        params.appealStartDate = this.appealTime[0];
        params.appealEndDate = this.appealTime[1];
      }
      if (this.processingTime) {
        params.disposeStartDate = this.processingTime[0];
        params.disposeEndDate = this.processingTime[1];
      }

      this.doFetch({
        url: "/biz/course/appeal/queryCourseAppealList",
        params,
        pageNum,
      });
    },
    getappealStatus() {
      const appealStatusList = this.$setDictionary("APPROVESTATE", "list");
      let list = [{ value: "", label: "全部" }];
      for (const key in appealStatusList) {
        list.push({
          value: key,
          label: appealStatusList[key],
        });
      }
      this.appealStatusList = list;
    },
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.theImgClick {
  border: 3px solid #f56c6c;
}
.operationControl .el-input input {
  width: 100% !important;
}
</style>
<style lang="less" scoped>
.searchbox > div .el-input {
  flex: 1;
}
// .el-dialog__body {
//   overflow: auto;
// }
// .projectUser {
//   min-width: 6rem !important;
// }
// .qrcode {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   img {
//     width: 400px;
//     height: 400px;
//     background-color: #999; //设置白色背景色
//   }
// }
// .refreshcode {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /* padding: 1rem; */
//   padding-top: 1rem;
// }
.searchBox {
  padding: 0;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
// .docsDialog {
//   .el-form-item {
//     margin-bottom: 0.5rem;
//   }
// }
// .ellipsis {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   height: 23px;
//   width: 60px;
//   display: block;
// }
.searchboxTwo {
  .searchboxItems {
    display: flex;
    align-items: center;
    .itemLabel {
      min-width: 5rem;
      text-align: right;
      font-size: 14px;
      padding: 0 0.5rem;
    }
  }
}
.searchboxItems {
  display: flex;
  align-items: center;
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.operationControlCurr {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .itemLabel {
    min-width: 5rem;
    text-align: right;
    font-size: 14px;
    padding: 0 0.5rem;
  }
}
.courseBox {
  display: flex;
  flex-wrap: wrap;
  .form-item {
    width: 50%;
  }
}
.noPassBtn {
  background-color: #999;
  border: 1px solid #999;
}
.noPassBtn:hover {
  background-color: #999;
  border: 1px solid #999;
}
</style>
